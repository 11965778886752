<template>
  <ol class="carousel__pagination" :class="class">
    <li class="carousel__pagination-item" v-for="(slide, index) in maxSlide + 1" :key="index">
      <span
        class="carousel__pagination-button"
        :class="{ 'carousel__pagination-button--active': isActive(index) }"
        @click="handleButtonClick(slide)"
      >{{ slide }}</span>
    </li>
  </ol>
</template>
<script>
import { inject, ref } from 'vue'

export default {
  props: {
    class: {
      type: String,
      default: ''
    }
  },
  setup() {
    const maxSlide = inject('maxSlide', ref(1))
    const minSlide = inject('minSlide', ref(1))
    const currentSlide = inject('currentSlide', ref(1))
    const nav = inject('nav', {})

    function handleButtonClick(slideNumber) {
      nav.slideTo(slideNumber - 1 )
    }

    const isActive = (slide) => {
      const val = currentSlide.value
      return (
        val === slide ||
        (val > maxSlide.value && slide >= maxSlide.value) ||
        (val < minSlide.value && slide <= minSlide.value)
      )
    }

    return {
      minSlide,
      maxSlide,
      handleButtonClick,
      isActive,
    }
  },
}
</script>
<style lang="scss" scoped>
.carousel__prev,
.carousel__next {
  background-color: var(--vc-nav-background-color);
  border-radius: var(--vc-nav-width);
  width: var(--vc-nav-width);
  height: var(--vc-nav-width);
  text-align: center;
  font-size: calc(var(--vc-nav-width) * 2 / 3);
  padding: 0;
  color: var(--vc-nav-color);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  border: 0;
  cursor: pointer;
}

.carousel__prev {
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.carousel__next {
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
</style>
